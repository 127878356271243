/**
 * @prettier
 */

// React Packages
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';

// Actions
import { clearError } from './actions/navigatorActions';
import { completeStage } from './actions/submissionStateActions';

// Services
import apiService from '../services/api/api';
import logService from '../services/shared/logService';
import DataDogService from '../services/shared/datadogService';
import documentService from '../services/shared/documentService';
import {
    getCompanyName,
    isSubmissionComplete,
    sanitiseImage,
} from '../services/shared/helpers';

//  Components
import Header from './Header';
import Navigator from './Navigator';
import CustomButton from './Button';
import InstructionModal from './Messages/InstructionModal';
import StageComplete from './Messages/StageComplete';

// Images
import livenessSvg from '../assets/img/liveness.svg';
import loadingGif from '../assets/gifs/loading.gif';
import livenessInstructionGif from '../assets/gifs/passive_instruction.gif';

// Config
import { imageAlt } from '../config/accessabilityRules';
import { ACTION_LABELS } from '../config/dataDogActionLabels';

const ACTION = ACTION_LABELS.captureSelfie;
const ISIDLIVEFACEENABLED = process.env.REACT_APP_IDLIVEFACE_ENABLED === 'true';

class IDPalCaptureSelfie extends Component {
    constructor(props) {
        super(props);
        this.primaryFocusRef = React.createRef();
        this.state = {
            loading: false,
            showInstructions: true,
            imageLoaded: false,
            livenessImage: null,
            navigation: {
                action: 'load',
                props: null,
            },
        };

        this.captureLivenessImage = this.captureLivenessImage.bind(this);
        this.startCamera = this.startCamera.bind(this);
    }

    componentDidMount() {
        // Sets focus to primary heading on first render
        if (this.primaryFocusRef && this.primaryFocusRef.current) {
            this.primaryFocusRef.current.focus();
        }

        // Sets document title
        const { t } = this.props;
        document.title = t('idpal_doc_title_liveness');
    }

    componentDidUpdate(prevProps, prevState) {
        // Sets focus to primary heading on first render
        if (this.primaryFocusRef && this.primaryFocusRef.current) {
            this.primaryFocusRef.current.focus();
        }

        // Sets document title
        const { t } = this.props;
        if (this.state.livenessError) {
            document.title = t('idpal_doc_title_liveness_error');
        } else {
            document.title = t('idpal_doc_title_liveness');
        }

        if (this.state.imageLoaded !== prevState.imageLoaded) {
            if (this.state.livenessImage !== null) {
                // For reusable profiles, use legacy endpoint
                if (this.props.context !== 'initial') {
                    this.submitLivenessImage(this.state.livenessImage);

                    // Otherwise send binary liveness to new endpoint
                } else {
                    this.submitLiveness(
                        documentService.urlToBlob(this.state.livenessImage),
                        'image'
                    );
                }
            }
        }
    }

    // Open camera input
    startCamera() {
        document.getElementById('selfieInput').click();
    }

    // Capture liveness image from native camera input
    captureLivenessImage() {
        const SELF = this;
        DataDogService.log('Capturing Image with ID-Pal Liveness.');
        document
            .getElementById('selfieInput')
            .addEventListener('change', function () {
                if (this.files && this.files[0]) {
                    const FILE_READER = new FileReader();
                    FILE_READER.readAsDataURL(this.files[0]);
                    FILE_READER.addEventListener('load', function (event) {
                        const IMAGE = new Image();
                        IMAGE.src = event.target.result;
                        IMAGE.onload = function () {
                            // Draw the image to canvas once it is captured
                            SELF.setState({
                                livenessImage: SELF.drawToCanvas(this),
                            });
                            SELF.setState({ imageLoaded: true });
                            SELF.setState({ showInstructions: false });
                        };
                    });
                } else {
                    logService.error('No image uploaded with ID-Pal Liveness');
                }
            });
    }

    // Draw captured image to canvas to retain image orientation on Android Phones
    drawToCanvas(image) {
        const hiddenCanvas = document.querySelector('#hiddenCanvasSelfie');
        hiddenCanvas.width = image.width;
        hiddenCanvas.height = image.height;

        const ctx = hiddenCanvas.getContext('2d');
        ctx.mozImageSmoothingEnabled = false;
        ctx.webkitImageSmoothingEnabled = false;
        ctx.msImageSmoothingEnabled = false;
        ctx.imageSmoothingEnabled = false;
        ctx.drawImage(image, 0, 0, image.width, image.height);

        const canvasImage = hiddenCanvas.toDataURL('image/jpeg');
        return canvasImage;
    }

    // Send Liveness Binary to Liveness endpoint for validation
    submitLiveness(binary, type) {
        clearError(this);
        this.setState({ loading: true });

        apiService
            .postPasssiveLiveness(binary, type)
            .then(response => {
                DataDogService.log('ID-Pal Liveness successfully sent.');
                this.props.completeStage('passive_liveness');
                this.setState({
                    navigation: {
                        action: 'next',
                    },
                });
            })
            .catch(error => {
                switch (error.status) {
                    case 420:
                        // Invalid verification
                        DataDogService.log(
                            'ID-Pal Liveness successfully sent, invalid result. Continuing. '
                        );
                        this.props.completeStage('passive_liveness');
                        this.setState({
                            navigation: {
                                action: 'next',
                            },
                        });
                        break;

                    case 429:
                        // Too many previous attempts
                        DataDogService.log(
                            'ID-Pal Liveness successfully sent, too many previous attempts. Continuing. '
                        );
                        this.props.completeStage('passive_liveness');
                        this.setState({
                            navigation: {
                                action: 'next',
                            },
                        });
                        break;

                    default:
                        // Actual error
                        DataDogService.createError(
                            'Unable to send IDR&D liveness for verification.'
                        );
                        this.setState({ loading: false });
                        logService.error(error);
                        this.setState({
                            navigation: {
                                action: 'error',
                                props: {
                                    retryAction: () =>
                                        this.submitLiveness(binary, type),
                                    error: error,
                                },
                            },
                        });
                }
            });
    }

    showInstructionModal() {
        if (ISIDLIVEFACEENABLED) {
            return false;
        }
        if (this.props.isPoaEnabled) {
            if (
                this.props.context === 'initial' &&
                this.props.docCompleted &&
                this.props.isDocSubmitted &&
                this.props.poaCompleted &&
                this.props.isPoaDocSubmitted
            ) {
                return true;
            }
        }

        if (!this.props.isPoaEnabled) {
            if (
                this.props.docCompleted &&
                this.props.isDocSubmitted &&
                this.props.context === 'initial'
            ) {
                return true;
            }
        }

        return false;
    }

    // Send Base64 Image to API for validation - LEGACY
    submitLivenessImage(base64Image) {
        const image = sanitiseImage(base64Image);

        clearError(this);
        this.setState({ loading: true });

        // Send passive liveness selfie base64Image to the server for processing
        apiService
            .getPassiveLivenessResult(image)
            .then(response => {
                this.props.dispatch({
                    payload: response,
                    type: '@@acuant/ADD_FACE_LIVENESS_DATA',
                });
                this.props.completeStage('passive_liveness');
                DataDogService.log(
                    'ID-Pal Liveness successfully sent for verification at legacy endpoint'
                );
                this.setState({
                    navigation: {
                        action: 'next',
                    },
                });
            })
            .catch(error => {
                DataDogService.createError(
                    'ID-Pal Liveness for verification at legacy endpoint.'
                );
                this.setState({ loading: false });
                logService.error(error);
                this.setState({
                    navigation: {
                        action: 'error',
                        props: {
                            retryAction: () =>
                                this.submitLivenessImage(base64Image),
                        },
                    },
                });
            });
    }

    render() {
        const { t } = this.props;

        if (isSubmissionComplete()) {
            return (
                <Fragment>
                    <Header />
                    <StageComplete
                        message={t('idpal_your_submission_is_complete', {
                            company: getCompanyName(this.props.companyName),
                        })}
                        hideContinue={true}
                        hideButton={true}
                    />
                </Fragment>
            );
        }

        return (
            <>
                {this.showInstructionModal() && (
                    <InstructionModal
                        heading={t('idpal_document_upload_completed')}
                        message={t('idpal_document_upload_completed_message')}
                        image={livenessInstructionGif}
                        cta={t('idpal_continue')}
                        showCta={true}
                    />
                )}

                {/* Component starts proper */}
                <Header />

                {/* Loading state */}
                {this.state.loading && (
                    <div className='o-site-wrap instructions'>
                        <div className='u-generic-text  u-text-center u-btm-buffer'>
                            <h1
                                ref={this.primaryFocusRef}
                                tabIndex={0}
                                className='loading-ellipse'
                            >
                                {t('idpal_analysing')}
                                <span className='dot1'>.</span>
                                <span className='dot2'>.</span>
                                <span className='dot3'>.</span>
                            </h1>
                        </div>

                        <div className='u-display-loading u-text-center'>
                            <img
                                alt={imageAlt.loading}
                                src={loadingGif}
                                className='capture'
                            />
                        </div>
                    </div>
                )}

                {/* Liveness capture instructions */}
                {this.state.showInstructions && !this.state.loading && (
                    <div className='o-site-wrap instructions'>
                        <h1
                            className='u-generic-text  u-text-center'
                            ref={this.primaryFocusRef}
                            tabIndex={0}
                        >
                            {ISIDLIVEFACEENABLED
                                ? t('idpal_unable_to_access_auto_capture')
                                : t('idpal_passive_liveness_instruction')}
                        </h1>

                        <div className='u-display-selfie u-text-center'>
                            <div className='u-display-img-wrap'>
                                <img
                                    alt={imageAlt.selfieInstructions}
                                    className='capture'
                                    src={livenessSvg}
                                />
                            </div>
                        </div>

                        <p className='u-generic-text  u-text-center'>
                            {ISIDLIVEFACEENABLED && (
                                <>
                                    {t(
                                        'idpal_we_were_unable_to_access_auto_capture'
                                    )}
                                    <br />
                                </>
                            )}
                            {t('idpal_take_photo_of_face')}
                        </p>

                        <div className='u-generic-text u-text-center'>
                            <CustomButton
                                id={'capture'}
                                className='btn'
                                label={t('idpal_open_camera')}
                                handleClick={this.startCamera.bind(this)}
                                actionDataLabel={ACTION.captureLivenessButton}
                            />
                        </div>
                    </div>
                )}

                {/* Hidden inputs*/}
                <input
                    className='hidden'
                    type='file'
                    id='selfieInput'
                    accept='image/*'
                    capture='user'
                    onClick={() => this.captureLivenessImage()}
                />
                <canvas className='hidden' id='hiddenCanvasSelfie'></canvas>
                <Navigator
                    page={'passive_liveness'}
                    action={this.state.navigation.action}
                    propsToPass={this.state.navigation.props}
                />
            </>
        );
    }
}

function mapDispatchToProps(dispatch) {
    const actions = bindActionCreators({ completeStage }, dispatch);
    return { ...actions, dispatch };
}

function mapStateToProps(state) {
    return {
        isDocSubmitted: state.config.isDocSubmitted,
        docCompleted:
            state.submissionState.submissionState.document_upload.completed,
        poaCompleted:
            state.submissionState.submissionState.poa_upload.completed,
        isPoaEnabled: state.submissionState.screens.poa_upload.enabled,
        context: state.submissionState.screens.passive_liveness.context,
        isPoaDocSubmitted: state.config.isPoaDocSubmitted,
        companyName: state.config.profile.data.company_branding.name,
    };
}

export default withTranslation('translation')(
    connect(mapStateToProps, mapDispatchToProps)(IDPalCaptureSelfie)
);
